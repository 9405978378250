import { TypeTestimonial } from '../../../@types/generated';
import { isTypeTestimonial } from '../../../@types/generated/TypeTestimonial';
import QuoteSlider from '../component-library/layout/QuoteCarousel';
import QuoteLayout from '../component-library/layout/QuoteLayout';
import { PageSectionComponentClientOnly } from './pageSections';
import orientedMediaToCompatibleProps from './util/orientedMediaToCompatibleProps';
import sectionToCompatibleProps from './util/sectionToCompatibleProps';

const QuotesSection: PageSectionComponentClientOnly = ({ section }) => {
  const quotesList = section.fields.references
    ?.filter((ref): ref is TypeTestimonial => !!ref && isTypeTestimonial(ref))
    .map((testimonial: TypeTestimonial) => {
      const {
        body,
        name,
        jobTitle,
        color,
        mediaBackground,
        mediaLogo,
        mediaPortrait,
        background: deprecatedBackground,
        companyLogo: deprecatedCompanyLogo,
        portrait: deprecatedPortrait,
        settings,
      } = testimonial.fields;
      const { image: portrait } = orientedMediaToCompatibleProps({
        orientedMedia: mediaPortrait,
        image: deprecatedPortrait,
      });
      const { image: companyLogo } = orientedMediaToCompatibleProps({
        orientedMedia: mediaLogo,
        image: deprecatedCompanyLogo,
      });
      const {
        image: background,
        imageLandscape: backgroundImageLandscape,
        imagePortrait: backgroundImagePortrait,
      } = orientedMediaToCompatibleProps({
        orientedMedia: mediaBackground?.fields.orientedMedia,
        image: deprecatedBackground,
      });

      return {
        identifier: '',
        body,
        name,
        jobTitle,
        settings,
        background: {
          landscape: backgroundImageLandscape || background,
          portrait: backgroundImagePortrait,
        },
        companyLogo,
        portrait,
        color,
      };
    });
  const { id } = sectionToCompatibleProps({ section });
  if (!quotesList?.length) return null;
  if (quotesList.length === 1) {
    const quote = quotesList[0];
    return <QuoteLayout id={id} {...quote} />;
  }
  return <QuoteSlider id={id} quotes={quotesList} />;
};

export default QuotesSection;
