import styled from '@emotion/styled';
import { TypeTestimonial } from '../../../../@types/generated';
import { breakpoints, colors, spacing } from '../../../utils/styleguide';
import Image, { ImageStyleProps, OrientedImage } from '../../contentful-elements/Image';
import RichText from '../../contentful-elements/RichText/RichText';
import HeadlinerBackground from '../HeadlinerBackground';
import Typography from '../text/Typography';
import { Col, Container, Row } from './12ColGridLayout';
import z from 'zod';
import { isObject } from '../../../utils/getSettingsFromSection';
import { captureException } from '../../../utils/tracking/sentry/sentry';

type ContentProps = {
  centering?: string;
};

const Content = styled(Container)`
  height: 100%;
  width: 100vw;
  min-height: 500px;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: ${({ centering }: ContentProps) => centering || 'center'};
  align-items: center;
  padding: ${spacing[6]}px ${spacing[2]}px ${spacing[11]}px;
  ${breakpoints.tablet} {
    padding: ${spacing[10]}px ${spacing[4]}px;
  }
  ${breakpoints.desktop} {
    padding: ${spacing[10]}px 0;
    min-height: 720px;
  }
`;

const QuoteRowSection = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const QuoterRowSection = styled(Row)`
  margin: ${spacing[4]}px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 ${spacing[8]}px;
  ${breakpoints.tablet} {
    padding: 0 ${spacing[10]}px;
  }
  ${breakpoints.desktop} {
    padding: 0 ${spacing[11]}px;
  }
`;

const CompanyLogo = styled(Image)`
  margin-top: ${spacing[4]}px;
  height: 32px;
  max-width: 100%;
  object-fit: contain;
`;

const ImpacterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ImpacterProfileImage = styled(Image)`
  width: 64px;
  height: 64px;
  object-fit: cover;
  z-index: 1;
  border-radius: 50%;
  border: solid 2px ${colors.white};
  background-color: ${colors.black};
  color: ${colors.white};
  margin-right: ${spacing[4]}px;
  ${breakpoints.tablet} {
    width: 80px;
    height: 80px;
  }
`;

export interface Quote extends Omit<TypeTestimonial['fields'], 'background'> {
  background?: OrientedImage;
}

type QuoteSlideProps = {
  id?: string;
} & Quote;

const settingsSchema = z.object({
  titleColor: z.string().optional(),
  color: z.string().optional(),
});

const QuoteSlide = ({
  id,
  background,
  body,
  name,
  jobTitle,
  settings,
  companyLogo,
  portrait,
  color,
}: QuoteSlideProps) => {
  const settingsObject = isObject(settings) ? settings : {};
  const { data: parsedSettings, error } = settingsSchema.safeParse(settingsObject);
  if (error) captureException(error);
  const safeColors: Record<string, string | undefined> = colors;
  const settingsTitleColor = parsedSettings?.titleColor
    ? safeColors[parsedSettings.titleColor]
    : undefined;
  const settingsColor = parsedSettings?.color ? safeColors[parsedSettings.color] : undefined;
  return (
    <HeadlinerBackground
      id={id}
      backgroundLinear="linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 60%, #000000 100%)"
      Image={(props: ImageStyleProps) => <Image asset={background} {...props} />}
    >
      <Content centering={name ? 'space-between' : 'center'}>
        <QuoteRowSection>
          {body && (
            <Col>
              <RichText document={body} textColor={settingsTitleColor} />
            </Col>
          )}
        </QuoteRowSection>
        {name && jobTitle && (
          <QuoterRowSection>
            <Col tablet="span 6">
              {portrait && (
                <ImpacterContainer>
                  <ImpacterProfileImage asset={portrait} alt={`${name} picture`} />
                  <div>
                    <Typography color={(color && colors[color]) || settingsColor || colors.black}>
                      {name}
                    </Typography>
                    <Typography
                      variant="detail"
                      color={(color && colors[color]) || settingsColor || colors.black}
                    >
                      {jobTitle}
                    </Typography>
                  </div>
                </ImpacterContainer>
              )}
              {companyLogo && !portrait && (
                <>
                  <Typography color={(color && colors[color]) || settingsColor || colors.black}>
                    {name}
                  </Typography>
                  <Typography
                    variant="detail"
                    color={(color && colors[color]) || settingsColor || colors.black}
                  >
                    {jobTitle}
                  </Typography>
                  {companyLogo && <CompanyLogo asset={companyLogo} />}
                </>
              )}
            </Col>
          </QuoterRowSection>
        )}
      </Content>
    </HeadlinerBackground>
  );
};

export default QuoteSlide;
